<template>
  <div class="skeepers_carousel_container keepers" data-slides-count="4"></div>
</template>
<script lang="ts" setup>
useHead({
  script: [
    {
      src: '//widgets.rr.skeepers.io/carousel/db557681-7a15-50e4-9110-e92e1b1a9641/ab7bf0ca-9642-4fab-90ac-a89f165281a9.js',
      async: true
    }
  ]
});
</script>
<style lang="scss" scoped>
.keepers {
  padding: 30px 0;

  /* stylelint-disable-next-line selector-class-pattern */
  :deep(.carousel__navigation) {
    display: none;
  }
}
</style>
